@import "_color-variables";
@import "_size-variables";
@import "a-app-foundation";

.p-menubar {
    //background: transparent;
    //border: none;

    .p-menuitem {
        //:not(:last-of-type) {
        //    margin-right: 5px;
        //}
    }
}

.p-inputtext:enabled:hover {
    &:not(.ng-dirty.ng-invalid) {
        border-color: #8dc73f;
    }
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem lighten(@ol-green, 30%);

    &:not(.ng-dirty.ng-invalid) {
        border-color: @ol-green;
    }
}


.ohh-carousel {
    .p-carousel-item {
        position: relative;
    }

    .p-carousel-indicator {
        &.p-highlight {
            button {
                background-color: @ol-green;
            }
        }
    }

    .p-carousel-prev:hover,
    .p-carousel-next:hover {
        //background-color: @ol-green;
        //opacity: 0.5;
        background-color: lightgrey
    }
}

.ohh-top-sidebar {
    &.p-sidebar-top {
        top: 114px;
        left: auto;
        width: @ol-max-content-width;
        max-width: 95%;

        @media (max-width: @ol-medium-break) {
            top: 67px;
        }
    }

    &.ng-trigger {
        height: auto;
    }
}

@ol-header-height-mobile: 111px;    // Ohh Look Header Height on Phone
@ol-header-height-tablet: 72px;     // Ohh Look Header Height on Tablet
@ol-device-footer-mobile: 89px;     // Phone Footer (e.g., address bar)
@ol-device-footer-tablet: 40px;     // Tablet Footer (e.g., address bar)

.ohh-mobile-sidebar {
    top: @ol-header-height-tablet;
    overflow: auto;
    max-height: calc(100vh - calc(@ol-header-height-tablet + @ol-device-footer-tablet));

    @media (max-width: @ol-mobile-break) {
        top: @ol-header-height-mobile;
        max-height: calc(100vh - calc(@ol-header-height-mobile + @ol-device-footer-mobile));
        width: 95%;
    }

    #ol-ls-container {
        #ol-menubar-container {
            &:not(.logged-in) {
                justify-content: center;
            }
        }
    }
}

@media (max-width: @ol-medium-break) {
    .p-component-overlay {
        &.p-sidebar-mask {
            top: @ol-header-height-tablet;

            @media (max-width: @ol-mobile-break) {
                top: @ol-header-height-mobile;
            }
        }
    }
}

.p-scrolltop {
    position: fixed;
    background: lightgrey;

    &:hover {
        background: darken(lightgrey, 10%);
    }
}

.ol-submenu-panel {
    .p-overlaypanel-content {
        padding: 15px 10px;
    }
}

// Services Panel
.ol-services-panel {

    .p-panelmenu-panel {
        margin-bottom: 10px;
    }

    .p-panelmenu-header-content {
        border: unset;
        background: transparent;
    }

    .p-panelmenu-content {
        border: unset;
        background: transparent;
    }

    .p-panelmenu-expanded {
        .p-panelmenu-content {
            margin-left: 10px;
            padding-top: 5px;

            .p-element {
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
    }
}

// Mobile Accordion
.ol-mobile-accordion {
    &:extend(.ol-body);
}

// Header Avatar
.ohh-avatar {
    &.p-avatar {
        border: 1px solid #eee;
        background-color: #eee;
    }
}

// Spinner
.ol-progress-spinner {
    .p-progress-spinner-circle {
        stroke: @ol-green !important;
    }
}

// Provider Account - Biz Profile

.ol-bp-specialties {
    &.p-chips {
        width: 100%;
    }

    ul.p-chips-multiple-container {
        width: 100%
    }
}

.ol-profile-section-container {
    .olps-header {
        p-triStateCheckbox {

            .p-checkbox {
                .p-checkbox-box {
                    &.p-highlight {
                        border-color: @ol-green;
                        background: @ol-green;

                        &:hover {
                            border-color: darken(@ol-green, 5%);
                            background: darken(@ol-green, 8%);
                        }
                    }
                }
            }

        }
    }
}

.ol-confirm-dlg {
    .p-button {
        background: @ol-green;
        border: @ol-green;

        &:hover {
            background: darken(@ol-green, 5%);
            border: darken(@ol-green, 5%);

            &:active {
                background: darken(@ol-green, 8%);
                border: darken(@ol-green, 8%);
            }
        }
    }
}

// Provider Details

.ol-provider-carousel {
    &.p-carousel {
        border: 1px solid #eee;

        .p-carousel-item {
            max-width: 425px;

            @media (max-width: 991px) {
                max-width: unset;
            }
        }

        &.account {
            border: unset;

            .p-carousel-item {
                max-width: 374px;
            }
        }
    }
}

// Business Profile

#go-live {
    .p-inputswitch.p-inputswitch-checked {

        .p-inputswitch-slider {
            background-color: @ol-green;
        }

        &:not(.p-disabled):hover {
            .p-inputswitch-slider {
                background-color: darken(@ol-green, 5%);;
            }
        }
    }
}
