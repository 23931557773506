/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap');

@import "primeicons/primeicons.css";

@import "styles/a-app-foundation";
@import "styles/b-prime-ng-overrides";
@import "styles/c-ohh-components";
