@import "./_size-variables";
@import "./_color-variables";

html,
body {
    width: 100%;
    min-width: @ol-min-content-width;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    color: #333;
    font-weight: 200;
    //font-family: 'Source Sans 3', sans-serif;
    //font-family: 'Nunito', sans-serif;
    font-family: 'proxima-nova', sans-serif;
}

p {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 1rem;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Source Sans 3', sans-serif;
    color: #333;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1 {
    font-size: 46px;

    strong {
        font-weight: 500;
    }
}

h3 {
    font-size: 28px;
    font-weight: 500;
}

h4 {
    font-size: 24px;
}

h6 {
    font-weight: 400;
    line-height: 1.2;
    font-size: 18px;
}

.ol-green-text {
    color: @ol-green;
}

.ol-flex-center {
    display: flex;
    justify-content: center;
}

.ol-flex-col {
    display: flex;
    flex-direction: column;
}

.ol-flex-col-center {
    &:extend(.ol-flex-col);
    align-items: center;
}


.ol-flex-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ol-pseudo-link {
    color: @ol-green;
    cursor: pointer;

    &:hover {
        filter: brightness(0.95);

        &:active {
            filter: brightness(0.9);
        }
    }
}

a:-webkit-any-link {
    text-decoration: none;
}

.ol-font-thin {
    font-weight: 100;
}

.ol-font-light {
    font-weight: 300;
}

.ol-font-regular {
    font-weight: 400;
}

.ol-font-semi-bold {
    font-weight: 500;
}

.ol-font-bold {
    font-weight: 700;
}

.ol-page-container {
    display: flex;
    flex-direction: column;
}

.ol-header {
    font-family: 'proxima-nova', sans-serif;
}

.ol-body {
    font-family: 'proxima-nova', sans-serif;
}

.ol-uppercase {
    text-transform: uppercase;
}

.ol-capitalize {
    text-transform: capitalize;
}

.ol-ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ol-ellipsis-span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Number of lines before truncation */
    overflow: hidden;
    text-overflow: ellipsis;
}

.ol-scroll-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
}

.toro-root {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    ohh-header,
    ohh-footer {
        width: 100%;
    }
}

.ol-field-error {
    color: red;
    font-size: 12px;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    pointer-events: none;
}

.ol-max-width-container {
    max-width: @ol-max-content-width;
    margin: 0 auto;
}

.ol-strong {
    font-weight: 600;
}

.ol-btn {
    cursor: pointer;

    &:hover {
        filter: brightness(0.95);

        &:active {
            filter: brightness(0.9);
        }
    }
}

.ol-std-btn {
    padding: 6px 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.green {
        background-color: @ol-green;
    }

    @media only screen and (max-width: 768px) {
        padding: 5px 50px;
    }
}

.ol-green-btn-text {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}

.ol-green-button {
    &:extend(.ol-btn);
    &:extend(.ol-std-btn);
    &:extend(.ol-std-btn.green);
    &:extend(.ol-green-btn-text);

    font-family: 'proxima-nova', sans-serif;
    border: none;

    &:hover {
        filter: brightness(0.95);

        &:active {
            filter: brightness(0.9);
        }
    }

    &:disabled {
        opacity: 0.6;
        color: lightgray;
        cursor: pointer;

        pointer-events: none;
    }

    &.inverted {
        background-color: white;
        color: @ol-green;
        border: 2px solid @ol-green;

        &:hover {
            background-color: rgba(141,199,63, 0.1);

            &:active {
                background-color: rgba(141,199,63, 0.20);
            }
        }
    }
}

.ol-hover-link {
    cursor: pointer;

    &:hover {
        color: @ol-green;
    }
}

.ol-text-link {
    cursor: pointer;

    &:hover {
        text-decoration: underline;

        //&:active {
        //    filter: brightness(0.9);
        //}
    }
}

.ol-search-bar-ig,
.ol-email-signup-ig {
    //width: fit-content;

    .p-button,
    .p-inputtext {
        border-radius: unset;

        &:focus {
            box-shadow: none;
            //box-shadow: 0 0 0 1px blue;
        }
    }

    .p-button {
        &:hover {
            filter: brightness(0.95);

            &:active {
                filter: brightness(0.9);
            }
        }
    }

    ::placeholder {
        color: #a5a5a5;
    }

    @media (max-width: @ol-mobile-break) {
        .p-button,
        .p-inputtext {
            font-size: small;
        }
    }
}

#ol-page-banner {
    height: 440px;

    @media (max-width: @ol-medium-break) {
        height: 300px;
    }

    @media (max-width: @ol-tablet-break) {
        height: 250px;
    }

    @media (max-width: @ol-mobile-break) {
        height: 200px;
    }

}

.p-galleria-close {
    z-index: 9999;
}

@media (max-width: 992px) {
    /* Styles for medium devices */
}

@media (max-width: 768px) {
    /* Styles for small devices */
}

@media (max-width: 576px) {
    /* Styles for extra small devices */
}
