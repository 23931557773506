@import "_color-variables";
@import "_size-variables";

// Set Ohh custom components to expand to container width.
ohh-page-footer {
    width: 100%;
}

ohh-section-header {
    //border: 1px solid green;

    #ol-section-header-container {
        &:extend(.ol-header);
        width: 100%;
        text-align: center;

        .ol-sh-marquee {
            &:extend(.ol-font-regular);
            font-size: 24px;
            color: @ol-green;
            margin-bottom: 16px;
        }

        .ol-sh-heading {
            //font-size: 47px;
            font-size: clamp(22px, calc(22px + (47 - 22) * @ol-font-clamp-const), 47px);
            font-weight: 100;
            line-height: 1.25;
            padding-bottom: 30px;
            margin-bottom: 30px;
            position: relative;

            &.smaller-text {
                font-size: clamp(18px, calc(18px + (36 - 18) * @ol-font-clamp-const), 36px);
            }

            &:not(.no-sep) {
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 37%;
                    width: 26%;
                    border-bottom: 3px solid @ol-green;
                }
            }

            &.no-sep {
                margin-bottom: 10px;
                padding-bottom: 0;
            }

            strong {
                &:extend(.ol-font-regular);
            }
        }

        .ol-sh-sub-heading {
            &:extend(.ol-font-regular);
            //font-size: 28px;
            font-size: clamp(20px, calc(20px + (28 - 20) * @ol-font-clamp-const), 28px);
            margin-bottom: 30px;

            &.italic {
                font-style: italic;
            }

            &.small-margin {
                margin-bottom: 20px;
            }
        }

        .ol-sh-body {
            &:extend(.ol-font-light);
            margin-top: 74px;
            line-height: 1.4;

            &.small-margin {
                margin-top: 36px;
            }

            &.narrow {
                max-width: 850px;
            }

            &.tiny-margin {
                margin-top: 20px;
            }

            &:extend(.ol-body);
            //font-size: 22px;
            font-size: clamp(16px, calc(16px + (22 - 16) * @ol-font-clamp-const), 22px);
            width: 65%;
            margin-left: auto;
            margin-right: auto;

            &.seventy-five {
                width: 75%;
            }
        }
    }
}

.ohh-avatar {
    &.p-avatar-lg {
        .p-avatar-text {
            font-size: 20px;
        }
    }
}

.ol-field-checkbox {
    padding-top: 5px;

    label {
        margin-left: 5px;
    }

    p-radioButton,
    p-checkbox {
        .p-radiobutton-box,
        .p-checkbox-box {
            &.p-highlight {
                border-color: @ol-green;
                background: @ol-green;
            }
        }
    }

    &.row {
        padding-bottom: 20px;

        p-radioButton,
        p-checkbox {
            margin-bottom: 12px;

            &:not(:last-of-type) {
                margin-right: 1rem;
            }

            &.service-category {
                margin: 0 1rem .5rem 0;
            }
        }

        &.locations {
            p-checkbox {
                min-width: 200px;
                margin-bottom: 16px;
            }
        }

    }

    &.col {
        display: flex;
        flex-direction: column;

        p-radioButton {
            &:not(:first-of-type) {
                margin-top: 1rem;
            }
        }
    }
}

.ol-labeled-input-text {
    position: relative;

    .ol-field-error {
        &:extend(.prevent-select);
        position: absolute;
        bottom: -18px;
        color: red;
        font-size: 12px;

        &.stacked {
            bottom: -16px;
        }

        span {
            white-space: nowrap;
        }
    }
}

.ol-form-error-box {
    &:extend(.prevent-select);
    height: 30px;
    color: red;
    font-size: 13px;
}

#ol-img-callout-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .ol-icc-img {
        height: 70px;
        margin-bottom: 20px;
    }

    .ol-icc-heading {
        &:extend(.ol-font-light);
        font-size: clamp(12px, calc(12px + (20 - 12) * @ol-font-clamp-const), 20px);
    }

    .ol-icc-body {
        font-weight: 400;
        line-height: 1.2;
        font-size: 18px;
        text-align: center;
    }
}

#ol-img-callout-container {
    flex-wrap: wrap;
    justify-content: center;


    ohh-image-callout-card {
        flex: 1;
        //border: 1px solid red;

        &.ol-icc-three-up {
            &:extend(.prevent-select);
            flex-basis: 33%;

            @media (max-width: @ol-medium-break) {
                flex-basis: 50%;
                max-width: 50%;
            }

            @media (max-width: @ol-mobile-break) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }
}

// Provider Card

ohh-provider-card {
    max-width: 100%;
}

//#ol-p-card-container {
//    #ol-provider-card-container {
//        @media (max-width: 420px) {
//            width: 90%;
//        }
//    }
//}

// Providers Search Page
#ol-services-container {
    #ol-split-container {
        #ol-split-page {
            #ol-section-header-container {
                margin-top: 20px;

                .ol-sh-marquee {
                    margin-bottom: 8px;
                }

                .ol-sh-body {
                    margin-top: 14px;
                }
            }
        }
    }
}

// Service Categories Page
#ol-service-cats-container {
    #ol-mega-menu-container {
        margin-bottom: 50px;
    }
}

// Neighborhood
#ol-neighborhood-container {
    #ol-city-highlights {
        .ol-ch-col {
            .ol-city-highlight {
                i {
                    color: @ol-green;
                    margin-right: 10px;
                }
            }
        }
    }

    ohh-section-header {
        #ol-section-header-container {
            .ol-sh-body {
                width: 76%;
            }
        }
    }
}

// Image Cropper
#ol-image-cropper-container {
    width: fit-content;

    image-cropper {
        max-width: 300px;
        max-height: 300px;
    }
}

// Prime-ng TabView
.ol-tab-view {
    .p-tabview-nav {
        justify-content: flex-end;
    }

    &.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        color: @ol-green;
        border-color: @ol-green;
    }
}

// Review Card
#ol-review-card-container {
    #ol-rc-review {
        .review-lb {
            height: 10px;
        }
    }
}

// Email Sent
.ol-bold-note {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
}
